import React from 'react'
import { graphql } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '/src/components/layout'
import { ProductList, ProductListFilters } from '/src/components/commerce'

const TubularSteelProductsPage = ({ data }) => {
  const { allCommerceProduct } = data

  return (
    <Layout>
      <Row className="mb-5">
        <Col md={4}>
          <ProductListFilters />
        </Col>
        <Col md={8}>
          <ProductList products={allCommerceProduct.edges} />
        </Col>
      </Row>
    </Layout>
  )
}

export default TubularSteelProductsPage

export const Head = () => <title>Structural Tube and Pipe Products | Metalcorp</title>

export const query = graphql`
{
  allCommerceProduct(
    filter: {relationships: {field_product_category: {elemMatch: {name: {in: "Structural Tube and Pipe"}}}}}
  ) {
    edges {
      node {
        id
        title
        path {
          alias
        }
        relationships {
          field_product_category {
            name
          }
          field_product_images {
            relationships {
              field_media_image_2 {
                gatsbyImage(
                  fit: COVER
                  formats: AUTO
                  height: 180
                  width: 180
                  layout: FIXED
                  quality: 100
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
}
`
